/* eslint-disable */
import React from 'react';
import RequestOffTable from './requestOffTable';
import TaskBar from './TaskBar/taskbar';

export default function RequestOff() {
  return (
    <div>
      <TaskBar />
      <RequestOffTable />
    </div>
  );
}
